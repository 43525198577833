import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArrangementService extends AbstractService {

  model = 'arrangements';

  getAllArrangements() {
    return this.http.get(`${environment.apiUrl}/${this.model}`);
  }

  getArrangementById(id) {
    return this.http.get(`${environment.apiUrl}/${this.model}/${id}`);
  }

  getDischardById(bindingId) {
    return this.http.get(`${environment.apiUrl}/debts/${bindingId}/discharge`);
  }

  getDischardLetterFile(bindingId) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiUrl}/debts/${bindingId}/discharge`, bindingId, httpOptions);
  }

  getDischardLetterEmail(bindingId) {
    return this.http.post(`${environment.apiUrl}/debts/${bindingId}/discharge/email`, bindingId);
  }

  generateBill(id, data) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiUrl}/${this.model}/${id}/bill`, data, httpOptions);
  }

  generatePix(id, data) {
    return this.http.post(`${environment.apiUrl}/${this.model}/${id}/pix`, data);
  }

  checkPaymentPix(installmentId) {
    return this.http.get(`${environment.apiUrl}/${this.model}/checkpixpayment?InstallmentId=${installmentId}`);
  }

  checkUnified() {
    return this.http.get(`${environment.apiUrl}/${this.model}/checkarrangements`);

  }
}
