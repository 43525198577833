<div class="modal-body">
  <div class="row">
    <div class="col-12 mb-4">
      <h3>{{ 'DEBT.PROPOSAL.RESUME' | translate }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h5>{{ 'DEBT.PROPOSAL.ACTUAL-DEBT' | translate }}</h5>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.ORIGINAL-VALUE' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong>{{ currentBalance | currency: 'BRL':'R$':'1.2-2' }}</strong>
    </div>
  </div>
  <div class="row pt-1 pb-3">
    <div class="col">
      <h6>{{ 'DEBT.ACTUAL-VALUE' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong>{{
        negotiation?.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2'
        }}</strong>
    </div>
  </div>
  <div class="row separator pt-3">
    <div class="col">
      <h5>{{ 'DEBT.PROPOSAL.ACCEPTED-RESUME' | translate }}</h5>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col text-right">
      <strong class="tag-discount">
        {{ negotiation.DiscountRate * 100 | number: '1.2-2' }} % Desconto
      </strong>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.PAYMENT-METHOD' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong *ngIf="paymentMethod == 3">
        {{ 'DEBT.PROPOSAL.BOLETO' | translate }}</strong>
      <strong *ngIf="paymentMethod == 11">
        {{ 'DEBT.PROPOSAL.PIX' | translate }}</strong>
    </div>
  </div>
  <!-- AQUI -->
  <div class="row pt-1" *ngIf="couponName != null">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.COUPON' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong> {{ couponName }}</strong>
    </div>
  </div>

  <div class="row pt-1">
    <div class="col">
      <h6 *ngIf="negotiation?.totalInstallments == 1">
        {{ 'DEBT.PROPOSAL.IN-CASH' | translate }}
      </h6>
      <h6 *ngIf="negotiation?.totalInstallments > 1">
        {{ 'DEBT.PROPOSAL.IN-INSTALMMENT' | translate }}
      </h6>
    </div>
    <div class="col text-right" *ngIf="negotiation?.totalInstallments > 1">
      <strong>

        <!-- (negotiation.downPaymentAmount | currency: 'BRL':'R$':'1.2-2') +
            ' + ' + -->
        {{
        negotiation.totalInstallments > 1
        ? (negotiation.downPaymentAmount
        | currency: 'BRL':'R$':'1.2-2') +
        ' + ' +
        (negotiation.totalInstallments - 1) +
        'x de ' +
        (negotiation.otherInstallmentsAmount
        | currency: 'BRL':'R$':'1.2-2')
        : (negotiation.downPaymentAmount
        | currency: 'BRL':'R$':'1.2-2')
        }}
      </strong>
    </div>
    <div class="col text-right" *ngIf="negotiation?.totalInstallments === 1">
      <strong>{{
        negotiation.arrangementAmount | currency: 'BRL':'R$':'1.2-2'
        }}</strong>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.TOTAL-VALUE' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong class="arrangement-amount">{{
        negotiation?.arrangementAmount | currency: 'BRL':'R$':'1.2-2'
        }}</strong>
    </div>
  </div>
  <div class="row due-date mt-3 mb-3">
    <div class="col p-0">
      <strong>{{ 'DEBT.PROPOSAL.DUE-DATE' | translate }}</strong>
    </div>
    <div class="col p-0 d-flex align-items-center justify-content-end text-right">
      <strong>{{ date | date: 'dd/MM/yyyy' }}</strong>
    </div>
  </div>
  <div class="row">
    <div class="col p-0 text-right">
      <button class="button outline mr-3 fechar-acordo-cancelar {{
          proposal.Portfolio
        }}" id="btn_cancel_acceptProposal" (click)="close()">
        {{ 'BUTTONS.CANCEL' | translate }}
      </button>
    </div>
    <div class="col p-0 text-right">
      <!-- à vista -->
      <button *ngIf="negotiation?.totalInstallments == 1" class="button primary" id="btn_confirm_send_acceptProposal"
        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
        data-evt-element-id="Confirmar oferta à vista" (click)="confirm()">
        {{ 'DEBT.PROPOSAL.BUTTON-SEND-CONFIRM' | translate }}
      </button>

      <!-- parcelada -->
      <button *ngIf="negotiation?.totalInstallments > 1" class="button primary" id="btn_confirm_send_acceptProposal"
        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
        data-evt-element-id="Confirmar oferta parcelada" (click)="confirm()">
        {{ 'DEBT.PROPOSAL.BUTTON-SEND-CONFIRM' | translate }}
      </button>
    </div>
  </div>
</div>