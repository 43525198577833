import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  constructor() { }

  show(toast: Object) {
    this.toasts.push(toast);
    setTimeout(() => {
      this.remove();
    }, 5000);
  }

  remove() {
    this.toasts = [];
  }
}
