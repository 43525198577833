<div class="container home-content channel-wrapper" *ngIf="router.url != '/precatorios'">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-spaces">
      <h2 class="home-title channel-section-title pb-4">
        {{ 'HOME.CHANNELS.TITLE' | translate }}
      </h2>
    </div>
  </div>
  <div class="hide-if-mobile">
    <div class="row channel-cards">
      <app-channel-card channelTitle="HOME.CHANNELS.PORTAL.TITLE" channelSubtitle="HOME.CHANNELS.PORTAL.SUBTITLE"
        srcIcon="assets/images/fi_globe.svg" srcIconWhite="assets/images/fi_globe_white.svg" action="HOME.CHANNELS.GO"
        targetType="intern" class="col-md-3" idGtag="cta_portal" (click)="portalButtonGTM()" fibbia_evt_collector="true"
        fibbia_evt_category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        fibbia_evt_type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" fibbia_evt_context_id="card_portal"
        fibbia_evt_element_id="Portal">
      </app-channel-card>
      <app-channel-card channelTitle="HOME.CHANNELS.CENTRAL.TITLE" channelSubtitle="HOME.CHANNELS.CENTRAL.SUBTITLE"
        srcIcon="assets/images/fi_phone.svg" srcIconWhite="assets/images/fi_phone_white.svg" action="HOME.CHANNELS.CALL"
        target="tel:08000250000" targetType="other" class="col-md-3" idGtag="cta_central" (click)="centralButtonGTM()"
        fibbia_evt_collector="true" fibbia_evt_category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        fibbia_evt_type="d2046231-877e-467c-b054-f05ff1a3b59a" fibbia_evt_context_id="card_central"
        fibbia_evt_element_id="Central">
      </app-channel-card>
      <app-channel-card channelTitle="HOME.CHANNELS.WHATSAPP.TITLE" channelSubtitle="HOME.CHANNELS.WHATSAPP.SUBTITLE"
        srcIcon="assets/images/fi_whatsapp.svg" srcIconWhite="assets/images/fi_whatsapp_white.svg"
        action="HOME.CHANNELS.GO" target="https://api.whatsapp.com/send?phone=5508000250000" targetType="extern"
        class="col-md-3" idGtag="cta_whatsapp" (click)="whatsappButtonGTM()" fibbia_evt_collector="true"
        fibbia_evt_category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        fibbia_evt_type="d2046231-877e-467c-b054-f05ff1a3b59a" fibbia_evt_context_id="card_whatsapp"
        fibbia_evt_element_id="Whatsapp ">
      </app-channel-card>
      <!-- <app-channel-card channelTitle="HOME.CHANNELS.MESSENGER.TITLE" channelSubtitle="HOME.CHANNELS.MESSENGER.SUBTITLE"
        srcIcon="assets/images/fi_message-circle.svg" srcIconWhite="assets/images/fi_message-circle_white.svg"
        action="HOME.CHANNELS.GO" target="https://sac-ipanemacm.bstecnologia.com.br/Chat/" targetType="extern"
        class="col-md-3" idGtag="cta_messenger" (click)="chatButtonGTM()">
      </app-channel-card> -->
    </div>
  </div>
  <div class="hide-if-desktop channel-swiper">
    <swiper [config]="config">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.PORTAL.TITLE" channelSubtitle="HOME.CHANNELS.PORTAL.SUBTITLE"
            srcIcon="assets/images/fi_globe.svg" srcIconWhite="assets/images/fi_globe_white.svg"
            (click)="portalButtonGTM()" action="HOME.CHANNELS.GO" target="entrar" targetType="intern"
            idGtag="cta_portal">
          </app-channel-card>
        </div>
        <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.CENTRAL.TITLE" channelSubtitle="HOME.CHANNELS.CENTRAL.SUBTITLE"
            srcIcon="assets/images/fi_phone.svg" srcIconWhite="assets/images/fi_phone_white.svg"
            action="HOME.CHANNELS.CALL" target="tel:08000250000" targetType="other" idGtag="cta_central">
          </app-channel-card>
        </div>
        <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.WHATSAPP.TITLE"
            channelSubtitle="HOME.CHANNELS.WHATSAPP.SUBTITLE" srcIcon="assets/images/fi_whatsapp.svg"
            srcIconWhite="assets/images/fi_whatsapp_white.svg" action="HOME.CHANNELS.GO"
            target="https://api.whatsapp.com/send?phone=5508000250000" targetType="extern" idGtag="cta_whatsapp">
          </app-channel-card>
        </div>
        <!-- <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.MESSENGER.TITLE"
            channelSubtitle="HOME.CHANNELS.MESSENGER.SUBTITLE" srcIcon="assets/images/fi_message-circle.svg"
            srcIconWhite="assets/images/fi_message-circle_white.svg" action="HOME.CHANNELS.GO"
            target="https://sac-ipanemacm.bstecnologia.com.br/Chat/" targetType="extern" idGtag="cta_messenger">
          </app-channel-card>
        </div> -->
      </div>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
</div>


<!-- para precatórios -->

<div class="container home-content channel-wrapper" *ngIf="router.url == '/precatorios'">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-spaces">
      <h2 class="home-title channel-section-title pb-4">
        {{ 'HOME.CHANNELS.TITLE-COURT-DEBT' | translate }}
      </h2>
    </div>
  </div>
  <div class="hide-if-mobile">
    <div class="row channel-cards">
      <app-channel-card channelTitle="HOME.CHANNELS.SIMULATION.TITLE"
        channelSubtitle="HOME.CHANNELS.SIMULATION.SUBTITLE" srcIcon="assets/images/fi_globe.svg"
        srcIconWhite="assets/images/fi_globe_white.svg" action="HOME.CHANNELS.GO" targetType="other" class="col-md-3"
        idGtag="cta_portal" (click)="onScrollBtn()" fibbia_evt_collector="true"
        fibbia_evt_category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        fibbia_evt_type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" fibbia_evt_context_id="card_portal"
        fibbia_evt_element_id="Portal">
      </app-channel-card>
      <app-channel-card channelTitle="HOME.CHANNELS.CENTRAL-COURT-DEBT.TITLE"
        channelSubtitle="HOME.CHANNELS.CENTRAL-COURT-DEBT.SUBTITLE" srcIcon="assets/images/fi_phone.svg"
        srcIconWhite="assets/images/fi_phone_white.svg" action="HOME.CHANNELS.CALL" target="tel:08000180048"
        targetType="other" class="col-md-3" idGtag="cta_central" (click)="centralButtonGTM()"
        fibbia_evt_collector="true" fibbia_evt_category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        fibbia_evt_type="d2046231-877e-467c-b054-f05ff1a3b59a" fibbia_evt_context_id="card_central"
        fibbia_evt_element_id="Central">
      </app-channel-card>
      <app-channel-card channelTitle="HOME.CHANNELS.WHATSAPP-COURT-DEBT.TITLE"
        channelSubtitle="HOME.CHANNELS.WHATSAPP-COURT-DEBT.SUBTITLE" srcIcon="assets/images/fi_whatsapp.svg"
        srcIconWhite="assets/images/fi_whatsapp_white.svg" action="HOME.CHANNELS.GO"
        target="https://api.whatsapp.com/send?phone=5511982738944" targetType="extern" class="col-md-3"
        idGtag="cta_whatsapp" (click)="whatsappButtonGTM()" fibbia_evt_collector="true"
        fibbia_evt_category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        fibbia_evt_type="d2046231-877e-467c-b054-f05ff1a3b59a" fibbia_evt_context_id="card_whatsapp"
        fibbia_evt_element_id="Whatsapp ">
      </app-channel-card>

    </div>
  </div>
  <div class="hide-if-desktop channel-swiper">
    <swiper [config]="config">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.SIMULATION.TITLE"
            channelSubtitle="HOME.CHANNELS.SIMULATION.SUBTITLE" srcIcon="assets/images/fi_globe.svg"
            srcIconWhite="assets/images/fi_globe_white.svg" (click)="onScrollBtn()" action="HOME.CHANNELS.GO"
            targetType="intern" idGtag="cta_portal" targetType="other">
          </app-channel-card>
        </div>
        <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.CENTRAL-COURT-DEBT.TITLE"
            channelSubtitle="HOME.CHANNELS.CENTRAL-COURT-DEBT.SUBTITLE" srcIcon="assets/images/fi_phone.svg"
            srcIconWhite="assets/images/fi_phone_white.svg" action="HOME.CHANNELS.CALL" target="tel:08000180048"
            targetType="other" idGtag="cta_central">
          </app-channel-card>
        </div>
        <div class="swiper-slide">
          <app-channel-card channelTitle="HOME.CHANNELS.WHATSAPP-COURT-DEBT.TITLE"
            channelSubtitle="HOME.CHANNELS.WHATSAPP-COURT-DEBT.SUBTITLE" srcIcon="assets/images/fi_whatsapp.svg"
            srcIconWhite="assets/images/fi_whatsapp_white.svg" action="HOME.CHANNELS.GO"
            target="https://api.whatsapp.com/send?phone=5511982738944" targetType="extern" idGtag="cta_whatsapp">
          </app-channel-card>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
</div>