import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-agreements-popup',
  templateUrl: './agreements-popup.component.html',
  styleUrls: ['./agreements-popup.component.scss']
})
export class AgreementsPopupComponent implements OnInit {
  @Input() unified: any

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      const modal = document.querySelector('ngb-modal-window');
      if (modal) {
        (modal as HTMLElement).scrollTo(0, 0);
      }
    }, 100);

  }

  close() {
    this.activeModal.dismiss();
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(
      () => {
        this.toastService.show({
          body: 'Copiado com sucesso!',
          class: 'bg-success',
        });
      },
      (err) => {
        this.toastService.show({
          body: 'Não foi possível copiar.',
          class: 'bg-danger',
        });
      }
    );
  }
}
